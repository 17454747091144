<template>
  <div>
    <portal :to='titleLocation'>
      <h1 class='text-2xl font-semibold px-8 py-8'>종목정보추가</h1>
    </portal>

    <div class='mb-4 flex leading-10'>
      <label class='inline-block w-1/4'>종목정보가져오기</label>
      <select v-model='selectedInvestment' class='form-select' @change='updateListingFromInvestment'>
         <option v-for='investment in investments'
           :key='`${investment.id}-${investment.investment_detail_id}`'
           :value='investment'>
           {{ investment.investment_name }}-{{investment.asset_class}}
         </option>
      </select>
    </div>

    <div class='mb-4 flex leading-10'>
      <label class='inline-block w-1/4'>* 종목구분</label>
      <select v-model='listing.asset_class' class='form-select'>
        <option value='CB'>CB</option>
        <option value='EB'>EB</option>
        <option value='BW'>BW</option>
        <option value='CPS'>CPS</option>
        <option value='RCPS'>RCPS</option>
        <option value='보통주'>보통주</option>
        <option value='우선주'>우선주</option>
        <option value='warrant'>warrant</option>
        <option value='사모사채'>사모사채</option>
        <option value='기타'>기타</option>
      </select>
    </div>
    <div class='mb-4 flex leading-10'>
      <label class='inline-block w-1/4'>* 구주/신주</label>
      <select v-model='listing.old_or_new' class='form-select'>
        <option value='구주'>구주</option>
        <option value='신주'>신주</option>
      </select>
    </div>
    <div class='mb-4 flex leading-10'>
      <label class='inline-block w-1/4'>종목명</label>
      <input class='form-input w-1/4' v-model='listing.name'>
    </div>
    <div class='mb-4 flex leading-10'>
      <label class='inline-block w-1/4'>단축코드</label>
      <input class='form-input w-1/4' v-model='listing.share_code'>
    </div>
    <div class='mb-4 flex leading-10'>
      <label class='inline-block w-1/4'>ISIN코드</label>
      <input class='form-input w-1/4' v-model='listing.isin_code'>
    </div>
    <div class='mb-4 flex leading-10'>
      <label class='inline-block w-1/4'>기업코드</label>
      <input class='form-input w-1/4' v-model='listing.company_code'>
    </div>
    <div class='mb-4 flex leading-10'>
      <label class='inline-block w-1/4'>회차</label>
      <input class='form-input w-1/4 text-right' type='number' v-model='listing.issued_seq'>
    </div>
    <div class='mb-4 flex leading-10'>
      <label class='inline-block w-1/4'>* 발행일</label>
      <el-date-picker
        v-model='listing.start_date'
        value-format='yyyy-MM-dd'
        type='date'
        placeholder='발행일 선택'>
      </el-date-picker>
    </div>
    <div class='mb-4 flex leading-10'>
      <label class='inline-block w-1/4'>* 전환가능일</label>
      <el-date-picker
        v-model='listing.conversion_date'
        value-format='yyyy-MM-dd'
        type='date'
        placeholder='전환가능일 선택'>
      </el-date-picker>
    </div>
    <div class='mb-4 flex leading-10'>
      <label class='inline-block w-1/4'>* 만기일</label>
      <el-date-picker
        v-model='listing.end_date'
        value-format='yyyy-MM-dd'
        type='date'
        placeholder='만기일 선택'>
      </el-date-picker>
    </div>
    <div class='mb-4 flex leading-10'>
      <label class='inline-block w-1/4'>* YTM(%)</label>
      <input class='form-input w-1/4 text-right' type='number' v-model='listing.ytm'>
    </div>
    <div class='mb-4 flex leading-10'>
      <label class='inline-block w-1/4'>* YTP(%)</label>
      <input class='form-input w-1/4 text-right' type='number' v-model='listing.ytp'>
    </div>
    <div class='mb-4 flex leading-10'>
      <label class='inline-block w-1/4'>* YTC(%)</label>
      <input class='form-input w-1/4 text-right' type='number' v-model='listing.ytc'>
    </div>
    <div class='mb-4 flex leading-10'>
      <label class='inline-block w-1/4'>* 표면이자율(%)</label>
      <input class='form-input w-1/4 text-right' type='number' v-model='listing.coupon_rate'>
    </div>
    <div class='mb-4 flex leading-10'>
      <label class='inline-block w-1/4'>리픽싱(%)</label>
      <input class='form-input w-1/4 text-right' type='number' v-model='listing.refixing_rate'>
    </div>
    <div class='mb-4 flex leading-10'>
      <label class='inline-block w-1/4'>리픽싱기준</label>
      <input class='form-input w-1/4 text-left' type='text' v-model='listing.refixing_base'>
    </div>
    <div class='mb-4 flex leading-10'>
      <label class='inline-block w-1/4'>리픽싱단위</label>
      <input class='form-input w-1/4 text-left' type='text' v-model='listing.refixing_unit'>
    </div>

    <portal :to='actionButtonsLocation'>
      <div class='flex flex-row justify-between w-full bg-gray-100 px-8 pr-8 py-4 text-sm shadow-lg'>
        <button 
          class='border px-6 h-12 text-sm text-white rounded-md font-bold' 
          style='background-color: #223645;'
          @click='submitListing'>저장</button>
        <div v-if='canDelete'>
          <button
            name='show-delete-btn'
            class='p-3 text-red-700
            text-sm border border-transparent hover:border-red-400 rounded-md'
            @click='deleteListing'>Delete <span class='font-semibold'>{{listing.name}}</span></button>
        </div>
      </div>
    </portal>
  </div>
</template>

<script>
import ListingsApi                           from '@/api/v1/listings'
import cloneDeep                             from 'lodash/cloneDeep'
import { mapState, mapGetters }              from 'vuex'
import InvestmentsApi                        from '@/api/v1/investments'

export default {
  name: 'ListingEdit',
  components: {
  },
  data () {
    return {
      showDelete: true,
      listing: {asset_class: '', is_direct: true},
      investments: [],
      selectedInvestment: {},
    }
  },
  computed: {
    ...mapState('listings', [
      'drilldownListing',
    ]),
    ...mapGetters('entities', [
      'drilldownEntityId',
    ]),
    canDelete () {
      return this.listing.id > 0
    },
    actionButtonsLocation () {
      return 'modal-footer'
    },
    titleLocation () {
      return 'modal-header'
    }
  },
  methods: {
    submitListing () {
      if (!this.listing.share_code || this.listing.share_code.length < 6) {
        alert('종목코드는 최소 6자리입니다 (ex: A005930)')
        return
      }

      if (this.listing.share_code[0] === 'F') {
        alert('직접투자자산이 아닙니다.')
        return
      }

      if (this.listing.id > 0) {
        ListingsApi.patchListing(this.listing.id, this.listing).then(resp => {
          this.doneEditing(resp)
        })
      } else {
        ListingsApi.postListing(this.listing).then(resp => {
          this.doneEditing(resp)
        })
      }
    },
    updateListingFromInvestment () {
      this.listing.asset_class = this.selectedInvestment.asset_class
      this.listing.old_or_new = this.selectedInvestment.is_new_shares
      this.listing.ytm = this.selectedInvestment.ytm
      this.listing.ytc = this.selectedInvestment.ytc
      this.listing.ytp = this.selectedInvestment.ytp
      this.listing.coupon_rate = this.selectedInvestment.coupon_rate
      this.listing.start_date = this.selectedInvestment.start_date
      this.listing.collection_date = this.selectedInvestment.collection_date
      this.listing.end_date = this.selectedInvestment.end_date
    },
    deleteListing () {
      var deleteConfirm = confirm(this.listing.name + ' 종목정보가 삭제됩니다')
      if (!deleteConfirm) {
        alert('종목정보 삭제가 취소되었습니다.')
        return
      }
      ListingsApi.deleteListing(this.listing.id).then((resp) => {
        this.doneEditing(resp)
      })
      this.showDelete = false
    },
    doneEditing (resp) {
      this.$emit('done-editing', resp)
    },
    getInvestmentEntity () {
      InvestmentsApi.getInvestmentForLedger({ entity_id: this.drilldownEntityId, is_direct: true, search_text: ''}).then(resp => {
        this.investments = resp
      })
    },
  },
  mounted () {
    this.listing = cloneDeep(this.drilldownListing)
    this.getInvestmentEntity ()
  },
}
</script>
